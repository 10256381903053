import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';
import { BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      bgPos="center"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      color="white"
    >
      <Box bgColor={BG_SECONDARY_TRANSPARENT} height="100%" padding="42px">
        <WithAnimation>
          <Text letterSpacing="2px" fontSize="md" fontWeight="light">
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            margin="32px 0"
            fontWeight="normal"
            size="4xl"
            letterSpacing="2px"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontWeight="light" fontStyle="italic" maxWidth="500px" letterSpacing="1px">
            {`“Kutemui jantung hatiku.”`}{' '}
            <br />— Kidung Agung 3:4 
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
