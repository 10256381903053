// Images BG
export const BG_AYAT = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Ayat_Portrait_GM3BuToaw.jpg?updatedAt=1686027902835`;
export const BG_COVER = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Cover_Portrait_tr7JCz6-6j.jpg?updatedAt=1686027902931`;
export const BG_PATTERN = `https://user-images.githubusercontent.com/10141928/147431083-48106e7a-9aed-4108-8ef6-8a666bd48b5a.jpeg`;
export const BG_WEDDING_DETAIL = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Background_1_Kj9iDmPW8.jpg?updatedAt=1686027903020`;
export const BG_WELCOME = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Cover_Dalam_Portrait_HM0uG58d8.jpg?updatedAt=1686027902872`;
export const BG_WISHLIST = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Background_3_p6jTQbkR6p.jpg?updatedAt=1686027902946`;
export const BG_YOUTUBE = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Ayat_Portrait_GM3BuToaw.jpg?updatedAt=1686027902835`;
export const BG_START_JOURNEY = BG_COVER;
export const BG_LOVESTORY = BG_COVER;
export const BG_FILTER = BG_COVER;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Background_2_tY_O6-fSq.jpg?updatedAt=1686027902385`;

// The Bride Photos
export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Groom_CdOyvNJxm.jpg?updatedAt=1686027901667`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Bride_8TvZ-DPFK.jpg?updatedAt=1686027902159`;

// COver Gallery & YouTube Thumbnail
export const COVER_YOUTUBE = `https://lh3.googleusercontent.com/-PzMzRP-ufY0/YZ-BUy08YNI/AAAAAAAAABQ/FrptBB-lxVkmzxl3GeIS5eBONvxZcVxwwCLcBGAsYHQ/flower.jpg`;
export const COVER_GALLERY_1 = `https://ik.imagekit.io/masansgaming/Oswald_Lucella/Foto_Background_2_tY_O6-fSq.jpg?updatedAt=1686027902385`;
// fill this constants below if you need more 1 cover gallery
export const COVER_GALLERY_2 = ``;
export const COVER_GALLERY_3 = ``;

// Invitato Logo
export const INVITATO_LOGO = `https://user-images.githubusercontent.com/10141928/137603877-5d2ddf10-39a7-4586-8ab1-d9cca7fe8898.png`;

// DON'T CHANGE WITH CDN IMAGE
import ImgQRCover from '../images/qrcover.jpg'
export const IMG_QR_COVER = ImgQRCover;

// Collaboration Vendor Logos
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_TIRTOWENING = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_prahasta = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';