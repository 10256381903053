import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

import guestList from '@/api/guest-data.json';
import { ENABLE_QR_INVITATION } from '@/constants/features-flag';

/**
 * Custom hook to return guest info data from Query string data
 * @return {object}
 */
function useGuestInfo() {
  const location = useLocation();
  const code = getQueryValue(location, 'code');
  const name = getQueryValue(location, 'to');
  const shift = getQueryValue(location, 'shift');
  const partner = getQueryValue(location, 'partner') || 0;

  if (!ENABLE_QR_INVITATION || !code) {
    return { name, shift, partner: Number(partner) };
  }

  const finalCode = decodeURIComponent(code);
  const guestInfo =
    guestList.find(
      (i) =>
        i.code.toLowerCase() === finalCode.toLowerCase() &&
        i.name.toLowerCase() === name.toLowerCase(),
    ) || {};

  return guestInfo;
}

export default useGuestInfo;
